import { Container, Row, Col } from 'react-bootstrap';
import logoBlanco from '../assets/img/logoBlanco.png';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import { Mailbox, Globe, Phone } from 'react-bootstrap-icons';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={4}>
            <img src={logoBlanco} alt="Logo" />
            <p className="about">
              Somos una Software Factory a medida en la que desarrollamos
              Sistemas y Aplicaciones. Contamos con: Diseñadores,
              Desarrolladores Front-End, Backend, Fullstack, entre otros.
            </p>
          </Col>
          <Col size={12} sm={4}>
            <div>
              <p>
                <Globe size={25} color={'rgba(210, 27, 27, 0.5)'} />
                <span> Rivadavia 13332</span> Ramos Mejía, Buenos Aires,
                Argentina.
              </p>
            </div>
            <div>
              <p>
                <Phone size={25} color={'rgba(210, 27, 27, 0.5)'} />
                <span> </span>
                <span> (+54) 01147944022 </span>
              </p>
            </div>
            <div>
              <p>
                <Mailbox size={25} color={'rgba(210, 27, 27, 0.5)'} />
                <span> </span>
                <span> contacto@juman-it.com.ar</span>
              </p>
            </div>
          </Col>
          <Col size={12} sm={4} className="text-center text-sm-end">
            <p className="menu">
              <a className="menu__footer_link" href="#home">
                {' '}
                Home
              </a>{' '}
              |{' '}
              <a className="menu__footer_link" href="#skills">
                Tecnologias
              </a>{' '}
              |{' '}
              <a className="menu__footer_link" href="#quienes-somos">
                Quienes Somos
              </a>{' '}
              |{' '}
              <a className="menu__footer_link" href="#clients">
                Clientes
              </a>{' '}
              |{' '}
              <a className="menu__footer_link" href="#contact">
                Contact
              </a>
            </p>
            <div className="social-icon">
              <a href="www.google.com.ar">
                <img src={navIcon1} alt="Icon" />
              </a>
              <a href="www.google.com.ar">
                <img src={navIcon2} alt="Icon" />
              </a>
              <a href="www.google.com.ar">
                <img src={navIcon3} alt="Icon" />
              </a>
            </div>
            <p>Copyright 2023. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
