import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const QuienesSomos = () => {
  return (
    <section className="project color-gris" id="quienes-somos">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? 'animate__animated animate__fadeIn' : ''
                  }
                >
                  <h2>Quiénes Somos?</h2>
                  <p></p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">Equipo</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Misión</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Visión</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? 'animate__animated animate__slideInUp' : ''
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <p>
                          Nuestro equipo está compuesto por expertos en
                          desarrollo de software web y consultoría empresarial.
                          Somos un grupo diverso de profesionales que combinamos
                          nuestras habilidades para brindar soluciones
                          innovadoras y de alta calidad a nuestros clientes.
                        </p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <p>
                          Nuestra misión es trabajar en colaboración con
                          nuestros clientes para entender sus objetivos y
                          desafíos empresariales y, a partir de ahí, desarrollar
                          soluciones que mejoren su eficiencia y rentabilidad.
                          Nos esforzamos por brindar un servicio excepcional y
                          un soporte continuo para asegurarnos de que nuestros
                          clientes estén siempre satisfechos.
                        </p>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <p>
                          Nuestra visión es ser líderes en el desarrollo de
                          software web y consultoría empresarial. Nos esforzamos
                          por ofrecer soluciones innovadoras y de calidad que
                          satisfagan las necesidades de nuestros clientes,
                          superando siempre sus expectativas. Creemos en la
                          importancia de establecer relaciones sólidas y
                          duraderas con nuestros clientes, para poder
                          acompañarlos en su crecimiento y evolución
                          empresarial.
                        </p>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
