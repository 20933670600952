import { Container, Row, Col } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import clientImg1 from '../assets/img/img-clientes/LPF-con-descripcion-2.png';
import clientImg2 from '../assets/img/img-clientes/corven.png';
import clientImg3 from '../assets/img/img-clientes/corvenHD.png';
import clientImg4 from '../assets/img/img-clientes/datacloud.png';
import clientImg5 from '../assets/img/img-clientes/datawise.png';
import clientImg6 from '../assets/img/img-clientes/escudo2.png';
import clientImg7 from '../assets/img/img-clientes/unico_logo_comprobante.png';
import colorSharp2 from '../assets/img/color-sharp2-gris.png';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Clients = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="client clients-bx" id="clients">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? 'animate__animated animate__fadeIn' : ''
                  }
                >
                  <div className="">
                    <h2>Clientes que nos acompañan</h2>
                    <p>
                      Tenemos el privilegio de trabajar con una amplia gama de
                      empresas de diferentes sectores y tamaños esforzándonos
                      por brindar soluciones personalizadas y de calidad a cada
                      una de ellas. Estamos comprometidos con su satisfacción y
                      valoramos la relación a largo plazo.
                    </p>
                    <Carousel
                      responsive={responsive}
                      infinite={true}
                      autoPlay={true}
                      autoPlaySpeed={2000}
                      removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
                      className="owl-carousel owl-theme skill-slider carousel-custom "
                    >
                      <div className="item__black" id="lpf-logo">
                        <img src={clientImg1} alt="react logo" />
                      </div>
                      <div className="item__black">
                        <img src={clientImg2} alt="vue logo" />
                      </div>
                      <div className="item__black">
                        <img src={clientImg3} alt="django logo" />
                      </div>
                      <div className="item__black">
                        <img src={clientImg4} alt="node logo" />
                      </div>
                      <div className="item__black">
                        <img src={clientImg5} alt="spring logo" />
                      </div>
                      <div className="item__black" id="argentinos-logo">
                        <img src={clientImg6} alt="powerbi logo" />
                      </div>
                      <div className="item__black" id="unico-logo">
                        <img src={clientImg7} alt="unico logo" />
                      </div>
                    </Carousel>
                  </div>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt=""></img>
    </section>
  );
};
