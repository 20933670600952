import { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const period = 2000;

  const toRotate = useMemo(
    () => ['Desarrollo de Software', 'Diseño de Software', 'Consultoría'],
    []
  );

  const tick = useCallback(() => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setLoopNum(loopNum + 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setIndex(1);
      setLoopNum(loopNum + 1);
      setDelta(500);
    } else {
      setIndex((prevIndex) => prevIndex + 1);
    }
  }, [isDeleting, loopNum, setIndex, setLoopNum, setText, toRotate, text]);

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [delta, tick]);

  const goToSection = () => {
    const contactSection = document.getElementById('connect');
    contactSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={9} xl={9}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? 'animate__animated animate__fadeIn' : ''
                  }
                >
                  <span className="tagline">Bienvenidos a Juman IT!</span>
                  <h1>{`Nosotros somos Juman IT`} </h1>
                  <h1>
                    <span
                      className="txt-rotate"
                      data-period="1000"
                      data-rotate='["Desarrollo de Software", "Diseño de Software", "Consultoría"]'
                    >
                      <span className="wrap">{text}</span>
                    </span>
                  </h1>
                  <p>
                    Somos una Software Factory a medida en la que desarrollamos
                    Sistemas y Aplicaciones. Contamos con: Diseñadores,
                    Desarrolladores Front-End, Backend, Fullstack, entre otros.
                    Tenemos experiencia en múltiples rubros de negocio y
                    diferentes tipos de soluciones como el desarrollo de
                    Aplicaciones Móviles y Plataformas Web.
                  </p>
                  <input type="hidden" value={index}></input>
                  <button onClick={goToSection}>
                    Contáctanos <ArrowRightCircle size={25} />
                  </button>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
