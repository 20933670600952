import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import contactImg from '../assets/img/contact-img.svg';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { useForm } from 'react-hook-form';

export const Contact = () => {
  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  };

  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  /* const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText('Sending...');
    let response = await fetch('http://localhost:5000/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(formDetails),
    });
    setButtonText('Send');
    let result = await response.json();
    setFormDetails(formInitialDetails);
    if (result.code === 200) {
      setStatus({ succes: true, message: 'Message sent successfully' });
    } else {
      setStatus({
        succes: false,
        message: 'Something went wrong, please try again later.',
      });
    }
  };*/

  const onSendHandler = () => {
    const { firstName, lastName, email, phone, message } = formDetails;
    const subject = `Nuevo mensaje de contacto de ${firstName} ${lastName}`;
    const body = `
    Email: ${email} 
    Teléfono: ${phone} 
    Mensaje: ${message}`;
    const mailtoUrl = `mailto:contacto@juman-it.com.ar?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.open(mailtoUrl, '_blank');

    setFormDetails(formInitialDetails);
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <img
                  className={
                    isVisible ? 'animate__animated animate__zoomIn' : ''
                  }
                  src={contactImg}
                  alt="Contact Us"
                />
              )}
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? 'animate__animated animate__fadeIn' : ''
                  }
                >
                  <h2>Contacto</h2>
                  <form onSubmit={handleSubmit(onSendHandler)}>
                    <Row>
                      <Col size={12} sm={6} className="px-1">
                        <input
                          type="text"
                          value={formDetails.firstName}
                          placeholder="Nombre"
                          {...register('Nombre', {
                            required: 'El campo Nombre es requerido ',
                          })}
                          onChange={(e) =>
                            onFormUpdate('firstName', e.target.value)
                          }
                        />
                        <p className="danger">{errors.Nombre?.message}</p>
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input
                          type="text"
                          value={formDetails.lastName}
                          placeholder="Apellido"
                          {...register('Apellido', {
                            required: 'El campo Apellido es requerido ',
                          })}
                          onChange={(e) =>
                            onFormUpdate('lastName', e.target.value)
                          }
                        />
                        <p className="danger">{errors.Apellido?.message}</p>
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input
                          type="email"
                          value={formDetails.email}
                          placeholder="Email"
                          {...register('Email', {
                            required: 'El campo Email es requerido ',
                          })}
                          onChange={(e) =>
                            onFormUpdate('email', e.target.value)
                          }
                        />
                        <p className="danger">{errors.Email?.message}</p>
                      </Col>
                      <Col size={12} sm={6} className="px-1">
                        <input
                          type="tel"
                          value={formDetails.phone}
                          placeholder="Teléfono No."
                          {...register('Teléfono', {
                            required: 'El campo Teléfono es requerido ',
                          })}
                          onChange={(e) =>
                            onFormUpdate('phone', e.target.value)
                          }
                        />
                        <p className="danger">{errors.Teléfono?.message}</p>
                      </Col>
                      <Col size={12} className="px-1">
                        <textarea
                          rows="6"
                          value={formDetails.message}
                          placeholder="Mensaje"
                          {...register('Mensaje', {
                            required: 'Debe ingresar un mensaje',
                          })}
                          onChange={(e) =>
                            onFormUpdate('message', e.target.value)
                          }
                        ></textarea>
                        <p className="danger">{errors.Mensaje?.message}</p>
                        <button type="submit">
                          <span>Enviar</span>
                        </button>
                      </Col>
                    </Row>
                  </form>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
