import django from '../assets/img/icons8-django.svg';
import react from '../assets/img/icons8-reaccionar.svg';
import vue from '../assets/img/vue-js.svg';
import node from '../assets/img/nodejs.svg';
import aws from '../assets/img/aws.svg';
import heroku from '../assets/img/heroku.svg';
import spring from '../assets/img/primavera.svg';
import powerbi from '../assets/img/power-bi.svg';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// import colorSharp from '../assets/img/color-sharp-gris.png';

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Tecnologías</h2>
              <p>
                Nos mantenemos actualizados sobre las últimas tendencias
                tecnológicas y nos aseguramos de que nuestros desarrolladores
                estén capacitados y especializados en las tecnologías más
                relevantes
              </p>
              <Carousel
                responsive={responsive}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={2000}
                removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
                className="owl-carousel owl-theme skill-slider"
              >
                <div className="item">
                  <img src={react} alt="react logo" />
                  <h5>React</h5>
                </div>
                <div className="item">
                  <img src={vue} alt="vue logo" />
                  <h5>Vue.js</h5>
                </div>
                <div className="item">
                  <img src={django} alt="django logo" />
                  <h5>Django</h5>
                </div>
                <div className="item">
                  <img src={node} alt="node logo" />
                  <h5>Node.js</h5>
                </div>
                <div className="item">
                  <img src={spring} alt="spring logo" />
                  <h5>SpringFramework</h5>
                </div>
                <div className="item">
                  <img src={powerbi} alt="powerbi logo" />
                  <h5>Power BI</h5>
                </div>
                <div className="item">
                  <img src={aws} alt="aws logo" />
                  <h5>AWS</h5>
                </div>
                <div className="item">
                  <img src={heroku} alt="heroku logo" />
                  <h5>Heroku</h5>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
