import './App.css';

import { NavBar } from './components/NavBar';
import { Skills } from './components/Skills.js';
import { QuienesSomos } from './components/QuienesSomos.js';
import { Clients } from './components/Clients.js';
import { Contact } from './components/Contact.js';
import { Footer } from './components/Footer.js';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Banner } from './components/Banner';

function App() {
  return (
    <div className="App">
      <NavBar></NavBar>
      <Banner></Banner>
      <Skills />
      <QuienesSomos></QuienesSomos>
      <Clients></Clients>
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
